var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "yfk" }, [
      _c("img", {
        staticClass: "bg",
        attrs: { src: require("../../static/image/msg.jpeg"), alt: "msg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }