<template>
	<div class="yfk">
		<img class="bg" src="../../static/image/msg.jpeg" alt="msg" />
	</div>
</template>

<script>
	export default {
		name: 'yufuka',
		created() {
			
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="less">
	.yfk {
		height: 100vh;
		
		.bg {
			width: 100%;
			height: 100%;
		}
	}
</style>
